import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import axios from 'axios';
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import "../AllCssFile.css";
import { MdDelete } from "react-icons/md";
import OrderPopUp from "./OrderPopUp";
import OrdercancelPopUp from "./OrdercancelPopUp";
import Pagination from "@mui/material/Pagination";
import OrderShippingPopUp from "./OrderShippingPopUp";
import { getOrderDatafetch, getOrderShippingstatus } from "../Controller/Order";
import { BsThreeDots } from "react-icons/bs";
import CategoryDelete from "./OrderDelete"
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { MdOutlineCancel } from "react-icons/md";


function ListOrder() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdPass, setItemId] = useState(null);
  const [itemIdToStatus, setItemIdStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // add this line
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [popUpOpenshipping, setPopUpOpenshipping] = useState(false);
  const [itemIdPassShipping, setItemIdShipping] = useState(null);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpOpenstatus, setPopUpOpenstatus] = useState(false);
  const [itemIdPasscancel, setItemIdcancel] = useState(null);
  const [itemIdTocancelstatus, setItemIdcancelstatus] = useState(null);
  const [orderList, setOrderData] = useState([]);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [popUpOpenDelete, setPopUpOpenDelete] = useState(false);
  const togglePopUp = (itemId, deliveryStatus) => {
    setPopUpOpen(!popUpOpen);
    setItemId(itemId);
    setItemIdStatus(deliveryStatus);
    getOrderdata();
  };

  const togglePopUpstatus = (itemId) => {
    console.log(itemId);
    setPopUpOpenstatus(!popUpOpenstatus);
    setItemIdcancel(itemId);
    getOrderdata();
  };
  /*************************************************************
   * Function Name  : getsocialdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getOrderdata = async (page, product_name) => {
    const options = {
      page: page,
      product_name: product_name,
    };

    try {
      const result = await getOrderDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.orders;
        const totalcount = result.data.response.result.totalCount;
        console.log(result.data.response.result.totalCount);
        console.log(responseData);
        setOrderData(responseData);
        setTotalItems(totalcount);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getOrderdata(page);
  };
  const handleSearch = (value) => {
    setSearchValue(value);
    getOrderdata(currentPage, value);
  };
  function formatStatus(status) {
    if (typeof status === 'string' && status.length > 0) {
      return status
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }
    return status;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getOrderdata(currentPage, searchValue);
  }, [currentPage, searchValue]);

  useEffect(() => {
    orderList.forEach(order => {
      if (order.shipment_order_id) {
        getOrderDataship(order.shipment_order_id);
      }
    });
  }, [orderList]);
  const getOrderDataship = async (order_id) => {
    try {

      const response = await axios({
        method: 'post',
        url: 'https://admin.mishrambu.in/admin/order/api/orders/show',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          id: order_id,
          auth: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjQ2ODg0ODYsInNvdXJjZSI6InNyLWF1dGgtaW50IiwiZXhwIjoxNzM0NTg4MzQxLCJqdGkiOiJlcklFeGVOa0NHOVdZdWhNIiwiaWF0IjoxNzMzNzI0MzQxLCJpc3MiOiJodHRwczovL3NyLWF1dGguc2hpcHJvY2tldC5pbi9hdXRob3JpemUvdXNlciIsIm5iZiI6MTczMzcyNDM0MSwiY2lkIjo2OTY0NTgsInRjIjozNjAsInZlcmJvc2UiOmZhbHNlLCJ2ZW5kb3JfaWQiOjAsInZlbmRvcl9jb2RlIjoiIn0.FJocKbVEPtATfJgLM-Vn74QU4nIITIVGnQ2QhcTZ5hQ"
        },
      });

      if (response.data) {
        let statusToSend = response.data.shipments.status;
        // Check if the status is "Pending"
        if (statusToSend === "PENDING") {
          statusToSend = "Processing";
        }
        const options = {
          shipment_order_id: order_id,
          status: formatStatus(statusToSend)
        };
        try {
          const resultstatus = await getOrderShippingstatus(options, adminInformation.token);
        } catch (error) {
          console.error("Error creating order:", error);
        }
      }
      // Update order data if necessary
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  const capitalizeFirstChar = (str) => {
    if (str) {
      return str.replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
    }
    return "";
  };
  const handleViewShipping = (item) => {
    console.log(item);
    setPopUpOpenshipping(!popUpOpenshipping);
    setItemIdShipping(item);
    getOrderdata();
  };
  const handleViewDetail = (item) => {
    navigate('/orderDetail', { state: { selectedItem: item } });
  };
  const togglePopUpDelete = (itemId) => {
    setPopUpOpenDelete(!popUpOpenDelete);
    setItemIdToDelete(itemId);
    getOrderdata();
  };
  const dropdownMenu = (item, shipment_order_id) => {
    let menuOptions;
    menuOptions = (
      <>
        {/* <Menu.Item key="3" onClick={() => togglePopUpDelete(item._id)}>
        <MdDelete />
        <span className="show_span_edit">Delete</span>
      </Menu.Item>  */}
        {item.status !== "Pending" && item.status.toLowerCase() !== "canceled" && (
          <Menu.Item key="3" onClick={() => togglePopUpstatus(shipment_order_id)}>
            <MdOutlineCancel />
            <span className="show_span_edit">Cancel</span>
          </Menu.Item>
        )}
        <Menu.Item key="4" onClick={() => handleViewDetail(item)}>
          <MdOutlineRemoveRedEye  />
          <span className="show_span_edit">View Details</span>
        </Menu.Item>
        {(item.status === "Pending") && (
          <Menu.Item key="5" onClick={() => handleViewShipping(item)}>
            <FaEdit />
            <span className="show_span_edit">Order Shipping</span>
          </Menu.Item>
        )}

      </>
    );

    return <Menu>{menuOptions}</Menu>;
  };


  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800"> Orders List </h1>
                <div className="table_head_main_class">
                  <div className="my_antd_date_picker1">
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6
                      className="m-0 font-weight-bold"
                      style={{ color: "#891621", fontWeight: "bold" }}
                    >
                      Orders Table List
                    </h6>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">S. No</th>
                            <th scope="col">Order Id</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Product Price</th>
                            <th scope="col">Payment Status</th>
                            <th scope="col">Order Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderList.length > 0 ? (
                            orderList.map((item, index) => (
                              <tr key={index}>
                                <td>{(currentPage - 1) * 10 + index + 1}</td>
                                <td>
                                  {item.order_id}
                                </td>
                                <td>
                                  {capitalizeFirstChar(item.user_id.fullName)}
                                </td>
                                <td>{item.user_id.email}</td>
                                <td>{item.user_id.mobile}</td>
                                <td>
                                  {item.cartItems.reduce((totalQuantity, cartItem) => totalQuantity + cartItem.variants.reduce((acc, variant) => acc + variant.quantity, 0), 0)}
                                </td>
                                <td>{item.total_price}</td>

                                <td style={{
                                  color: item.payment_status === "Pending" ? "red" : "green",
                                }}>{item.payment_status}</td>

                                <td

                                  style={{
                                    color: item.status === "Canceled" || item.status === "Pending" ? "red" : "green",
                                  }}
                                >
                                  {formatStatus(item.status)}
                                </td>
                                <td>
                                  <Dropdown
                                    overlay={() =>
                                      dropdownMenu(item, item.shipment_order_id)
                                    }
                                    placement="bottomLeft"
                                    arrow
                                  >
                                    <BsThreeDots />
                                  </Dropdown>
                                </td>

                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="table_pagination_sales_order">
                        <Pagination
                          count={Math.ceil(totalItems / 10)}
                          page={currentPage}
                          onChange={(event, page) => handlePageChange(event, page)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderPopUp
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        itemIdPass={itemIdPass}
        itemIdToStatus={itemIdToStatus}
      />
      <OrderShippingPopUp
        popUpOpenshipping={popUpOpenshipping}
        handleViewShipping={handleViewShipping}
        itemIdPassShipping={itemIdPassShipping}
      />
      <OrdercancelPopUp
        popUpOpenstatus={popUpOpenstatus}
        togglePopUpstatus={togglePopUpstatus}
        itemIdPasscancel={itemIdPasscancel}
      />
      <CategoryDelete
        popUpOpenDelete={popUpOpenDelete}
        togglePopUpDelete={togglePopUpDelete}
        itemIdToDelete={itemIdToDelete}
      ></CategoryDelete>
      {/* <OrdercancelPopUp
        popUpOpenstatus={popUpOpenstatus}
        togglePopUpstatus={togglePopUpstatus}
        itemIdPasscancel={itemIdPasscancel}
        itemIdTocancelstatus={itemIdTocancelstatus}
      /> */}
    </div>
  );
}

export default ListOrder;
